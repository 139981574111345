import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import Meta, { MetaProps } from "../components/Meta"
import VimeoPlayer from "../components/VimeoPlayer"

interface VideoPageTemplateProps {
  vimeoId: string,
  fullScreen: boolean,
  autoplay: boolean,
  loop: boolean,
  muted: boolean,
  playsinline: boolean
}

const VideoPageTemplate: FC<VideoPageTemplateProps> = ({
  vimeoId,
  fullScreen,
  autoplay,
  loop,
  muted,
  playsinline
}) => (
  <main
    id="video-page"
    className="h-screen bg-black"
  >
    <VimeoPlayer
      vimeoId={vimeoId}
      fullScreen={fullScreen}
      autoplay={autoplay}
      loop={loop}
      muted={muted}
      playsinline={playsinline}
    />
  </main>
)

interface VideoPageProps {
  data: {
    markdownRemark: {
      frontmatter: VideoPageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const VideoPage: React.FC<VideoPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <VideoPageTemplate
        vimeoId={frontmatter.vimeoId}
        fullScreen={frontmatter.fullScreen}
        autoplay={frontmatter.autoplay}
        loop={frontmatter.loop}
        muted={frontmatter.muted}
        playsinline={frontmatter.playsinline}
      />
    </>
  )
}

export default VideoPage

export const VideoPageQuery = graphql`
  query VideoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          css
          js
        }
        vimeoId
        fullScreen
        autoplay
        loop
        muted
        playsinline
      }
    }
  }
`
